<template>
  <div class="login-layout has-background-white-bis">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "LoginLayout"
};
</script>

<style lang="scss" scoped>
.login-layout {
  height: 100vh;
}
</style>
