import axios from "axios";

const removeToken = () => {
  delete axios.defaults.headers.common.Authorization;
  localStorage.removeItem("token");
  axios.token = null;
};

const interceptResErrors = err => {
  return Promise.reject(err);
};

const interceptResponse = res => res;

axios.interceptors.response.use(interceptResponse, interceptResErrors);

export default {
  addTokenFromLocalStorage() {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      axios.token = token;
      axios.defaults.headers.common.Authorization = token.id;
    }
  },

  setToken(token) {
    axios.token = token;
    axios.defaults.headers.common.Authorization = token.id;
    localStorage.setItem("token", JSON.stringify(token));
  },

  removeToken,

  setUID(uid) {
    axios.defaults.headers.common["x-uid"] = uid;
    localStorage.setItem("uid", JSON.stringify(uid));
  },

  getUID() {
    return JSON.parse(localStorage.getItem("uid"));
  },

  removeUID() {
    delete axios.defaults.headers.common["x-uid"];
    localStorage.removeItem("uid");
  }
};
