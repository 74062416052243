import Vue from "vue";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://8afb2202ca0645019f2d0244b8ec93bc@o193797.ingest.sentry.io/5341229",
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    logErrors: true,
    environment: process.env.NODE_ENV
  });
}
