import ActionTypes from "@/constants/action-types";
import ChekoutTypes from "@/constants/checkout/type";
import i18n from "@/i18n";

i18n.locale = navigator.language.substring(0, 2);
export const initialState = () => ({
  demo: false,
  editMode: false,
  websiteId: null,
  sheet: null,
  sheetId: null,
  sheetName: "",
  domain: "",
  subdomain: "",
  gaId: "",
  items: [],
  fields: [],
  filters: [],
  isPro: false,
  selectedFilter: null,
  siteName: "My website",
  fonts: [],
  logo: "",
  favicon: "",
  metaFields: {
    homeDescription: "",
    homeTitle: "",
    itemDescription: "",
    itemTitle: ""
  },
  listItemConfig: {
    topContent: "",
    captionField: "",
    descriptionField: "",
    filterByField: "",
    imageField: {
      name: [],
      type: "URL"
    },
    titleField: "",
    customFields: [],
    ctaField: {
      field: {
        name: "",
        type: ""
      },
      text: "",
      bgColor: "#3398db",
      color: "#ffffff",
      type: ActionTypes.NUM_CHECKOUT
    },
    hero: {
      show: false,
      subheading: {
        value: "",
        color: "#ffffff"
      },
      title: {
        value: "",
        color: "#ffffff"
      },
      image: {
        value: ""
      }
    }
  },
  detailConfig: {
    captionField: "",
    descriptionField: "",
    hide: false,
    imageField: {
      name: [],
      type: "URL"
    },
    titleField: "",
    customFields: [],
    ctaField: {
      field: {
        name: "",
        type: ""
      },
      text: "",
      bgColor: "#3398db",
      color: "#ffffff",
      type: ActionTypes.NUM_LINK
    }
  },
  checkout: {
    helloMessage: i18n.t("whatsAppCheckout.hello_customer"),
    phoneNumber: "",
    priceField: "",
    messageEnding: "",
    type: ChekoutTypes.NUM_WHATSAPP,
    branches: [],
    form: {
      paymentMethods: {
        cash: false,
        creditCard: false,
        custom: []
      },
      branch: {
        show: false,
        required: false
      },
      name: {
        show: true,
        required: true
      },
      email: {
        show: false,
        required: false
      },
      address: {
        show: false,
        required: false
      },
      country: {
        show: false,
        required: false
      },
      notes: {
        show: true,
        required: false
      },
      phoneNumber: {
        show: false,
        required: false
      },
      pickup: {
        available: true
      },
      delivery: {
        available: true,
        cost: ""
      },
      city: {
        show: false,
        required: false
      },
      state: {
        show: false,
        required: false
      },
      zipCode: {
        show: false,
        required: false
      },
      googleMaps: false
    }
  },
  paymentCfg: {
    active: false,
    paymentMethods: ["stripe", "paypal"],
    currencies: [
      { key: "mxn", value: "mxn" },
      { key: "usd", value: "usd" }
    ],
    selectedPaymentMethod: "",
    selectedCurrency: "",
    textButtonPay: "",
    stripeCfg: {
      publicKey: "",
      privateKey: ""
    }
  },
  productOptions: [],
  netlifyId: null,
  createdAt: {
    nanoseconds: 0,
    seconds: 0
  },
  footer: {
    show: false,
    content: "",
    description: "",
    backgroundColor: "#ffffff",
    textColor: "#000000",
    socials: {
      facebook: { show: false, url: "" },
      instagram: { show: false, url: "" },
      x: { show: false, url: "" },
      tiktok: { show: false, url: "" }
    }
  },
  theme: {
    backgroundColor: "#f3f4f6",
    navbarColor: "#ffffff",
    font: {}
  },
  pwa: {
    active: false,
    color: "",
    name: "",
    icon: ""
  },
  version: "",
  userAdmin: {},
  fixedNav: false,
  hasErrorSpreadsheet: false
});

export default initialState();
