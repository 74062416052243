const NUM_SINGLE_CHOICE = 1;
const NUM_MULTIPLE_CHOICE = 2;

const SINGLE_CHOICE = "Single choice";
const MULTIPLE_CHOICE = "Multiple choice";

const options = [
  { value: NUM_SINGLE_CHOICE, text: "Single choice" },
  { value: NUM_MULTIPLE_CHOICE, text: "Multiple choice" }
];

const get = {};
get[NUM_SINGLE_CHOICE] = SINGLE_CHOICE;
get[NUM_MULTIPLE_CHOICE] = MULTIPLE_CHOICE;

export default { get, options, NUM_SINGLE_CHOICE, NUM_MULTIPLE_CHOICE };
