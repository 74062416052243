<template>
  <div class="relative">
    <div
      id="div-suscription-body"
      class="flex flex-grow flex-wrap justify-center mt-12 modal-content-container"
    >
      <div
        class="modal-center-content divider flex flex-grow flex-col justify-center"
      >
        <div class="flex flex-col">
          <div class="flex flex-grow justify-between items-center mb-8 row">
            <div class="left-title-website">
              <span class="font-bold">
                {{ $t("subscription_modal.pro_website") }}
              </span>
            </div>
            <div class="right-option-website flex items-center">
              <div class="selected-name-website mt-1">
                <span>
                  {{ website.name }}
                </span>
              </div>
              <div class="edit-input-select-option-website">
                <div class="ml-2">
                  <a @click.prevent="onClickChangeWebsiteLicense">
                    <img class="img-icon" src="@/assets/icons/edit.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-grow justify-between items-center mb-8 row">
            <div class="left-title-website">
              <span class="font-bold">{{
                $t("subscription_modal.next_billing")
              }}</span>
            </div>
            <div class="right-option-website flex items-center">
              <div class="selected-name-website mt-1">
                <span>
                  {{ website.limitDate }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-grow justify-between items-center mb-8 row">
            <div class="left-title-website">
              <span class="font-bold">
                {{ $t("subscription_modal.plan") }}
              </span>
            </div>
            <div class="right-option-website flex items-center">
              <div class="selected-name-website mt-1">
                <span>
                  <strong>
                    {{ amount }}
                  </strong>
                </span>
              </div>
              <div class="edit-input-select-option-website">
                <div class="ml-2">
                  <a
                    @click.prevent="
                      onClickChangeSubscriptionPeriod(website.subscriptionId)
                    "
                  >
                    <img class="img-icon" src="@/assets/icons/edit.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-grow justify-between items-center mb-8 row">
            <div class="left-title-website">
              <span class="font-bold">{{ $t("payment_method") }}</span>
            </div>
            <div class="right-option-website flex items-center">
              <div class="selected-name-website mt-1 flex items-center">
                <div class="ml-2">
                  <a>
                    <img
                      class="img-icon"
                      src="@/assets/icons/credit-card.svg"
                    />
                  </a>
                </div>
                <div class="ml-2">
                  <a>
                    <img class="stripe-icon" src="@/assets/icons/stripe.svg" />
                  </a>
                </div>
                <div class="ml-2">
                  <a
                    @click.prevent="
                      onClickChangePaymentType(website.subscriptionId)
                    "
                  >
                    <img class="img-icon" src="@/assets/icons/edit.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col flex-grow justify-center items-center">
            <b-button
              type="is-ghost"
              class="text-center font-medium rounded mt-4 link-button w-60"
              @click.prevent="handleClickReturn"
            >
              {{ $t("subscription_modal.return") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
      :can-cancel="false"
    >
      <b-icon
        pack="fas"
        icon="arrows-rotate"
        size="is-large"
        custom-class="fa-spin"
      >
      </b-icon>
    </b-loading>
  </div>
</template>
<style lang="scss" scoped>
.opacity-med {
  opacity: 0.5;
}
.modal-content-container {
  max-width: 640px;
  width: 100%;
  .modal-center-content {
    max-width: 500px;
    width: 100%;
  }
}
.img-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.stripe-icon {
  width: 40px;
}
.link-button {
  border: 0;
  padding: 0;
  color: #0a69ff;
  &:hover {
    color: #2b7cfe;
    background-color: #e2edff;
  }
}
</style>
<script>
import { noop } from "@/utils/functions";
export default {
  props: {
    website: {
      type: Object,
      default: () => ({})
    },
    onClickReturn: {
      type: Function,
      default: noop,
      required: true
    },
    onClickChangePaymentType: {
      type: Function,
      default: noop
    },
    onClickChangeSubscriptionPeriod: {
      type: Function,
      default: noop
    },
    onClickChangeWebsiteLicense: {
      type: Function,
      default: noop
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    amount() {
      const { currency, totalAmount, plan } = this.website;
      return `${totalAmount} ${currency}/${plan}`;
    }
  },
  methods: {
    handleClickReturn() {
      this.onClickReturn(["subscriptionId", "editOption"]);
    }
  }
};
</script>
