import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins";
import "./styles/index.scss";
import i18n from "./i18n";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBemKlQe7B9AcUqSjtnZTBDdvjoqBgck7s",
    libraries: "visualization"
  }
});
Vue.component("google-map", VueGoogleMaps.Map);
Vue.component("google-marker", VueGoogleMaps.Marker);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
