<template>
  <div
    id="id-div-switch-subscription-root-container"
    @click="!returning && handleClickOutside($event)"
  >
    <div class="container relative flex flex-grow flex-col justify-center">
      <div class="flex flex-col items-center justify-center">
        <span>
          {{ $t("subscription_modal.assign_subscription") }}
        </span>
        <span>
          <strong>
            {{ proWebsite.name }}
          </strong>
        </span>
      </div>
      <div class="relative flex flex-col flex-grow py-8">
        <span>
          <strong>
            {{ $t("subscription_modal.select_free_website") }}
          </strong>
        </span>
        <div class="select-container w-3/4 rounded-md">
          <div
            class="p-2 flex justify-between"
            @click.prevent.stop="handleClickToggleDropdown"
          >
            <span v-if="selected && selected.name !== ''">
              {{ selected.name }}
            </span>
            <span v-else class="select-placeholder">
              {{ $t("subscription_modal.select_free_website") }}
            </span>
            <div class="flex items-center">
              <a v-if="selected" @click.prevent.stop="hancleClearSelected">
                <img src="@/assets/icons/cross-close.svg" />
              </a>
              <a class="ml-2">
                <img
                  src="@/assets/icons/chevron-right.svg"
                  width="24"
                  height="24"
                />
              </a>
            </div>
          </div>
          <div
            id="is-div-select-options-container"
            ref="dropdown"
            class="select-options-container"
            :class="classes.dropdownClass"
            @blur="handleClickOutside"
          >
            <div
              v-for="website of freeWebsites"
              :key="website.id"
              class="p-3 option-item"
              @click="setSelectedFreeWebsite(website)"
            >
              <span>{{ website.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-button flex flex-col items-center justify-center my-4">
      <b-button
        type="is-dark"
        class="font-medium rounded mt-4 px-4 w-60"
        :disabled="selected === null"
        @click="onClickSwitchSubscription"
      >
        <div class="flex items-center justify-center">
          <b-icon
            v-if="loading"
            pack="fas"
            icon="sync-alt"
            size="is-small"
            custom-class="fa-spin"
          >
          </b-icon>
          <span>{{ $t("custom_domain_modal.save") }}</span>
        </div>
      </b-button>
      <b-button
        type="is-ghost"
        class="text-center font-medium rounded mt-4 link-button w-60"
        @click="handleClickReturn"
      >
        {{ $t("subscription_modal.return") }}
      </b-button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.container {
  min-height: 320px;
  max-width: 500px;
}
.select-container {
  position: relative;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  min-width: 200px;
  transition: box-shadow 0.2s ease;
  z-index: 1;
  cursor: pointer;
}

.select-options-container {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  left: 0;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
  margin-top: 4px;
  transition: all 0.1s ease-in;
  border-radius: 0.3rem;
  overflow-y: scroll;
  z-index: -1;
  opacity: 0;
  height: 0;
  transform: translateY(-20px);
}

.select-options-container > .option-item:hover {
  cursor: pointer;
}

.select-options-container.dropdown-open {
  opacity: 1;
  transform: translateY(0px);
  height: auto;
}

.select-placeholder {
  color: #a7a6ac;
}

.link-button {
  border: 0;
  padding: 0;
  color: #0a69ff;
  &:hover {
    color: #2b7cfe;
    background-color: #e2edff;
  }
}
</style>
<script>
import { noop } from "lodash-es";
import { mapGetters } from "vuex";
export default {
  props: {
    proWebsite: {
      type: Object,
      default: () => ({})
    },
    onClickSave: {
      type: Function,
      default: noop
    },
    onClickReturn: {
      type: Function,
      default: noop
    }
  },
  data() {
    return {
      websiteName: "",
      classes: {
        dropdownClass: ""
      },
      returning: false,
      selected: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters(["freeWebsites"])
  },
  methods: {
    onClickSwitchSubscription() {
      if (this.selected) {
        this.loading = true;
        const self = this;
        this.onClickSave(this.selected, error => {
          self.loading = false;
          if (!error) {
            self.onClickReturn(["subscriptionId", "editOption"]);
          }
        });
      }
    },
    handleClickOpenSelector() {
      this.openDropdown();
    },
    handleClickToggleDropdown() {
      const classDropdown = this.classes.dropdownClass;
      this.classes.dropdownClass = classDropdown !== "" ? "" : "dropdown-open";
    },
    handleClickOutside(event) {
      const element = this.$refs.dropdown;
      if (
        !element.contains(event.target) &&
        this.classes.dropdownClass !== ""
      ) {
        this.openDropdown(false);
      }
    },
    openDropdown(open = true) {
      this.classes.dropdownClass = open ? "dropdown-open" : "";
    },
    setSelectedFreeWebsite(website) {
      this.selected = website;
      this.openDropdown(false);
    },
    hancleClearSelected() {
      this.selected = null;
      this.openDropdown(false);
    },
    handleClickReturn() {
      this.returning = true;
      this.onClickReturn(["editOption"]);
    }
  }
};
</script>
