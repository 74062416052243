<!-- eslint-disable prettier/prettier -->
<template>
  <div class="container mx-auto py-4 px-4 sm:px-0" style="max-width: none;">
    <div class="flex mainBar">
      <div class="contentJustifyLeft">
        <div>
          <button class="buttonMainSales" @click="returnMainPage">
            <img class="imgSmall" src="@/assets/icons/home.svg" />
            <span>{{ $t("sales_website.start") }}</span>
          </button>
        </div>
      </div>
      <div class="contentJustifyCenter displayFlex"></div>
      <div class="contentJustifyLeft">
        <span class="textNewCard">
          {{ websiteName }}
        </span>
      </div>
    </div>
    <div class="creative-bar">
      <div>
        <div>
          <div>
            <div id="containerFilters" class="grid grid-cols-4 justify-center	items-end	textAlignCenter">
              <div class="grid grid-cols-2 justify-center content-center	place-content-end">
                <div class="grid">
                  <button id="BtnOrder" class="BtnOrder" @click="changeFocus('BtnOrder')">
                    <img class="imgSmall" src="@/assets/icons/round-list.svg" />
                    {{ $t("sales_website.orders") }}
                  </button>
                </div>
                <div class="grid">
                  <button id="BtnSales" class="BtnSales" @click="changeFocus('BtnSales')">
                    <img class="imgSmall" src="@/assets/icons/chart-line.svg" />
                    {{ $t("sales_website.sales") }}    
                  </button>
                </div>
              </div>
              <div id="filterSearch" class="grid grid-cols-1 justify-center content-center	alignCenter">
                <input v-model="filtro" class="search-bar1" :placeholder="$t('sales_website.search')" icon-name="find" />
                <b-icon class="icon-search1" name="search" />
              </div>

              <div id="filterDatePicker" class="grid grid-cols-1 justify-center	items-end	content-center">

                <DatePickerComponent></DatePickerComponent>
              </div>
              <div id="filterEstatus" class="grid grid-cols-1 justify-center	items-end	content-center">
                <p class="marginLeft5 justifySelfStart">
                  {{ $t("sales_website.status") }}
                </p>
                <select id="colorSelect" v-model="filtroSelect" class="selectInside" @change="filteredVentasEstatus()">
                  <option value="">{{ $t("sales_website.all") }}</option>
                  <option value="1">{{ $t("sales_website.in_preparation") }}</option>
                  <option value="2">{{ $t("sales_website.on_the_way") }} </option>
                  <option value="3">{{ $t("sales_website.ready") }} </option>
                  <option value="4">{{ $t("sales_website.delivered") }} </option>
                  <option value="5">{{ $t("sales_website.cancelled") }}</option>  
                </select>
              </div>
            </div>
            <div id="containerTable" class="marginTop2">
              <table class="fullWidth">
                  <thead>
                    <tr>
                      <th class="tableHeaders textAlignCenter maxWidthMidCell">
                        {{ $t("sales_website.table_headers.number_of_order") }}
                      </th>
                      <th class="tableHeaders textAlignCenter maxWidthMidCell">
                        {{ $t("sales_website.table_headers.order_date") }}
                      </th>
                      <th class="tableHeaders textAlignCenter maxWidthMidCell">
                        {{ $t("sales_website.table_headers.order") }}
                      </th>
                      <th class="tableHeaders textAlignCenter maxWidthMidCell">
                        {{ $t("sales_website.table_headers.amount") }}
                      </th>
                      <th class="tableHeaders textAlignCenter maxWidthMidCell">
                        {{ $t("sales_website.table_headers.status") }}
                      </th>
                      <th class="tableHeaders textAlignCenter maxWidthMidCell">
                        {{ $t("sales_website.table_headers.pay") }}
                      </th>
                      <th class="tableHeaders textAlignCenter maxWidthMidCell">
                        {{ $t("sales_website.table_headers.order_receipt") }}
                      </th>
                      <th class="tableHeaders textAlignCenter maxWidthMidCell">
                        {{ $t("sales_website.table_headers.order_price") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(venta, index) in filteredVentas && filteredVentasEstatus" :key="index">
                      <td class="contentJustifyCenter textAlignCenter numOrden">
                        <p class="textSize"> {{ venta["Numero de orden"] }} </p>
                      </td>
                      <td class="contentJustifyCenter textAlignCenter ">
                        <p class="textSize width70"> {{ venta["Fecha del Pedido"] }} {{ venta["Hora del Pedido"] }} </p>
                      </td>
                      <td class="contentJustifyCenter textAlignCenter maxWidthMidCell thirdWidth">
                        <p class="textSize marginTop3"> {{ venta.Pedido }} </p>
                      </td>
                      <td class="contentJustifyCenter textAlignCenter maxWidthMidCell">
                        <p class="textSize"> {{ venta.Cantidad }}</p>
                      </td>
                      <td class="contentJustifyCenter textAlignCenter maxWidthMidCell">
                         
                        <select :id="'colorSelect' + index" v-model="venta.Estatus" class="selectTable" @change="changeColor('colorSelect' + index)">
                          <option value="1" class="red textSize">{{ $t("sales_website.in_preparation") }}</option>
                          <option value="2" class="green textSize">{{ $t("sales_website.on_the_way") }}</option>
                          <option value="3" class="blue textSize">{{ $t("sales_website.ready") }}</option>
                          <option value="4" class="yellow textSize">{{ $t("sales_website.delivered") }}</option>
                          <option value="5" class="purple textSize">{{ $t("sales_website.cancelled") }}</option>
                        </select>

                      </td>
                      <td class="contentJustifyCenter textAlignCenter maxWidthMidCell">
                        <div v-if="venta.Pago  == 1">
                          <p class="textSize contentJustifyCenter textAlignCenter"> {{ $t("sales_website.pay_on_line") }}</p>
                          </div>
                        <div v-else>
                          <p class="textSize contentJustifyCenter textAlignCenter">{{ $t("sales_website.pay_in_store") }} </p>
                        </div>
                      </td>
                      <td class="contentJustifyCenter textAlignCenter maxWidthMidCell">
                        <div v-if="venta.Pago  == 1" class="tableRowDelivery contentJustifyCenter textAlignCenter">
                          <img class="imgSmall" src="@/assets/icons/truck-delivery.svg" />
                          <p class="textSize"> {{ $t("sales_website.home_deliver") }}</p>
                          </div>
                        <div v-else class="tableRowDelivery contentJustifyCenter textAlignCenter">
                          <img class="imgSmall" src="@/assets/icons/lucide_store.svg" />
                          <p class="textSize contentJustifyCenter textAlignCenter"> {{ $t("sales_website.store_pickup") }}</p>
                        </div>
                      </td>
                      <td class="contentJustifyCenter textAlignCenter maxWidthMidCell">
                        <p class="textSize">{{ venta["Precio del pedido"] }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="
        grid
        gap-4
        grid-cols-1
        sm:grid-cols-2
        md:grid-cols-2
        lg:grid-cols-3
        xl:grid-cols-3
        recoilDiv
      "></div>
    <div>

    </div>
  </div>
</template>

<script>
import BIcon from "@/components/BIcon";
import * as Sentry from "@sentry/browser";
import DatePickerComponent from "./datePicker.vue";
import "vue2-datepicker/index.css";

export default {
  name: "SalesWebsite",

  components: {
    BIcon,
    DatePickerComponent
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    websiteUrl: {
      type: String,
      required: true
    },
    websiteName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      publishing: false,
      qrCode: "",
      time1: Date.now(),
      fechaSeleccionada: Date.now(),
      prefix: "",
      columns: [
        "Numero de orden",
        "Fecha del Pedido",
        "Pedido",
        "Cantidad",
        "Estatus",
        "Pago",
        "Recepción de pedido",
        "Precio del pedido"
      ],
      ventas: [
        {
          "Numero de orden": "#1929810",
          "Fecha del Pedido": "20/06/2024 8:22:37 hrs",
          "Hora del Pedido ": "8:23:33",
          Pedido:
            "Café especialidad Cielo Late, Capricho de Vainilla, Frío Café & Caramelo, Latte Frío de Coco, Dulce de Almendra, Galleta de la...",
          Cantidad: "6",
          Pago: 1,
          Estatus: "2",
          "Recepción de pedido": "Entrega a domicilio",
          "Precio del pedido": "$533.00"
        },
        {
          "Numero de orden": "#1929811",
          "Fecha del Pedido": "21/06/2024 11:44:23 hrs",
          "Hora del Pedido ": "18:54:59",
          Pedido: "Café especialidad Cielo Late",
          Cantidad: "1",
          Pago: 2,
          Estatus: "4",
          "Recepción de pedido": "Recoger en tienda",
          "Precio del pedido": "$89.00"
        }
      ],
      filtro: "",
      filtroSelect: ""
    };
  },
  computed: {
    filteredVentas() {
      return this.ventas.filter(pedido => {
        // Filtrar por cada campo de los pedidos
        return Object.values(pedido).some(value =>
          value
            .toString()
            .toLowerCase()
            .includes(this.filtro.toLowerCase())
        );
      });
    },
    filteredVentasEstatus() {
      if (this.filtroSelect === "") {
        return this.ventas.filter(pedido => {
          return Object.values(pedido).some(value =>
            value
              .toString()
              .toLowerCase()
              .includes(this.filtro.toLowerCase())
          );
        });
      }
      return this.ventas.filter(pedido => {
        return Object.values(pedido).some(value =>
          value
            .toString()
            .toLowerCase()
            .includes(this.filtro.toLowerCase())
            .filter(pedido => pedido.Estatus === this.filtroSelect)
        );
      });
    }
  },
  methods: {
    async returnMainPage() {
      try {
        this.$router.push({
          name: "dashboard"
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async changeFocus(id) {
      const selectElement = document.getElementById("BtnSales");
      const selectElement2 = document.getElementById("BtnOrder");

      const filterSearch = document.getElementById("filterSearch");
      const filterDatePicker = document.getElementById("filterDatePicker");
      const filterEstatus = document.getElementById("filterEstatus");
      const containerTable = document.getElementById("containerTable");

      if (id == "BtnSales") {
        filterSearch.style.visibility = "hidden";
        filterDatePicker.style.visibility = "hidden";
        filterEstatus.style.visibility = "hidden";
        containerTable.style.visibility = "hidden";

        selectElement.style.boxShadow = "2px 2px 10px 0px #00000040";
        selectElement.style.color = "#262626";

        selectElement2.style.boxShadow = "none";
        selectElement2.style.color = "#97989E";
      } else {
        filterSearch.style.visibility = "visible";
        filterDatePicker.style.visibility = "visible";
        filterEstatus.style.visibility = "visible";
        containerTable.style.visibility = "visible";

        selectElement2.style.boxShadow = "2px 2px 10px 0px #00000040";
        selectElement2.style.color = "#262626";
        selectElement.style.boxShadow = "none";
        selectElement.style.color = "#97989E";
      }
    },
    async changeColor(id) {
      try {
        const selectElement = document.getElementById(id);

        selectElement.addEventListener("change", function() {
          const selectedValue = selectElement.value;
          switch (selectedValue) {
            case "1":
              selectElement.style.backgroundColor = "#ffefbf";
              break;
            case "2":
              selectElement.style.backgroundColor = "#b2e1ff";
              break;
            case "3":
              selectElement.style.backgroundColor = "#b2e1ff";
              break;
            case "4":
              selectElement.style.backgroundColor = "#acffd9";
              break;
            default:
              selectElement.style.backgroundColor = "#ffd6d6";
          }
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.imgSmall {
  display: inline;
}

.textSize {
  font-size: 0.8em;
}

.fullWidth {
  width: 100%;
}

.thirdWidth {
  width: 35%;
}

.width70 {
  max-width: 70%;
}

.StatusSelect {
  margin-left: 2%;
  margin-right: 1%;
  margin-bottom: 2%;
  justify-content: center;
  align-items: baseline;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.selectInside {
  width: 50%;
  border-width: 1.5px;
  border-radius: 5px;
  height: 34px;
  margin-left: 5%;
  margin-right: 10%;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.BtnOrder {
  width: 60%;
  height: 36px;
  background-color: white;
  justify-self: end;
  align-content: center;
  border-radius: 20px;
  box-shadow: 2px 2px 10px 0px #00000040;
  margin-left: 2%;
  margin-right: 2%;
}

.BtnSales {
  width: 60%;
  height: 36px;
  background-color: transparent;
  color: #97989e;
  justify-self: start;
  align-content: center;
  border-radius: 20px;
  box-shadow: none;
  margin-left: 2%;
  margin-right: 2%;
}

.search-bar1 {
  width: 90%;
  height: 34px;
  border-radius: 5px;
  border-color: #d0d0d0;
  border: 1.5px solid #d0d0d0;
}
.creative-bar {
  justify-content: center;
  width: 100%;
  z-index: 2;
}

.icon-search1 {
  position: absolute;
  margin-left: 0.5%;
}

.justifySelfStart {
  justify-self: flex-start;
}

.centerDiv {
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
}

.flexDireccionColumn {
  flex-direction: column;
}

.flexDireccionRow {
  flex-direction: row;
}

.mainBar {
  justify-content: space-around;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1%;
}

.textNewCard {
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.25px;
}

.iconSmall {
  width: 21px;
  height: 21px;
}

.datePicker1 {
  height: 34px;
}

.buttonMainSales {
  background-color: transparent;
  border-radius: 20px;
  width: 100px;
  height: 34px;
  display: flex;
  margin-left: 0%;
  margin-right: 0%;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
}

.buttonMainSales:hover {
  background-color: #ceced0;
}

.displayFlex {
  display: flex;
}

.tableRowDelivery {
  display: flex;
  /* font-size: 12px; */
  flex-direction: row;
}

.selectTable {
  font-size: 0.8em;
  border-radius: 5px;
}

.contentJustifyLeft {
  justify-content: left;
}

.contentJustifyCenter {
  justify-content: center;
}

.contentJustifyRight {
  justify-content: right;
}

.textAlignStart {
  text-align: center !important;
  align-content: start;
}

.textAlignCenter {
  text-align: center !important;
  align-content: center;
}

.tableHeaders {
  background-color: #505057;
  align-content: center;
  color: white;
  height: 48px;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-color: #999999;

  th {
    color: white;
    text-align: center;
  }

  td {
    text-align: none;
  }
}

.centerRows {
  text-align: center;
}

.alignCenter {
  align-items: center;
  align-content: center;
}

.negativeMargin6 {
  margin-top: -6%;
}

.negativeMargin5 {
  margin-top: -5%;
}

.marginTop2 {
  margin-top: 2%;
}

.marginTop3 {
  margin-top: 3%;
}

.marginLeft5 {
  margin-left: 5%;
}

.maxWidthMidCell {
  max-width: 500px;
}

.leftTab {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.numOrden {
  color: #0a69ff;
  text-decoration: underline;
}

.tab-content {
  padding: 0rem !important;
  padding-top: 1% !important;
}

.b-tabs .tab-content {
  background-color: green !important;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.zIndexTop {
  z-index: 10;
}

.repairSection {
  padding: 0rem !important;
  padding-top: 1% !important;
  margin: -1rem;
}

.topDown2 {
  margin-top: -2%;
}

.shadowBox {
  box-shadow: 2px 2px 10px 0px #00000040;
}

.estatusPreparacion {
  background-color: #ffefbf;
}

.estatusCamino {
  background-color: #b2e1ff;
}

.estatusListo {
  background-color: #b2e1ff;
}

.estatusEntregado {
  background-color: #acffd9;
}

.estatusCancelado {
  background-color: #ffd6d6;
}

.red {
  background-color: red;
  color: white;
}

.green {
  background-color: green;
  color: white;
}

.blue {
  background-color: blue;
  color: white;
}

.yellow {
  background-color: yellow;
  color: black;
}

.purple {
  background-color: purple;
  color: white;
}

#colorSelect {
  color: black;
}

#colorSelect option {
  color: black;
}

.textoEstatusPreparacion {
  color: #846300;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
  align-items: center;
  align-content: center;
}

ul {
  z-index: 10 !important;
}

input {
  padding-left: 28px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: padding-left 0.2s ease;
}

.tabs.is-toggle li.is-active a {
  background-color: transparent;
  border-color: #262626;
  color: black;
  z-index: 10;
}

body {
  font: size 0.8em !important;
}

input:focus {
  padding-left: 25px;
}
</style>
