<template>
  <div class="flex flex-col relative">
    <div class="flex justify-center items-center mb-3">
      <span>{{ $t("subscription_modal.pro_website") }}</span>
      <span>
        <strong class="ml-1">
          {{ website.name }}
        </strong>
      </span>
    </div>
    <div class="flex flex-shrink justify-center">
      <div class="custom-tabs">
        <input
          id="id-rdo-1"
          type="radio"
          name="custom-tabs"
          value="Month"
          :checked="checkedMonthly"
          @change="handleChangeChecked"
        />
        <label for="id-rdo-1" class="custom-tab">
          {{ $t("pro_website_features.monthly_payment") }}
        </label>
        <input
          id="id-rdo-2"
          type="radio"
          name="custom-tabs"
          value="Year"
          :checked="checkedYearly"
          @change="handleChangeChecked"
        />
        <label for="id-rdo-2" class="custom-tab">
          {{ $t("pro_website_features.annual_payment") }}
        </label>
        <span class="glider"></span>
      </div>
    </div>
    <div class="flex plan-cards justify-center mt-8">
      <div
        v-for="product of formatedProducts"
        :id="product.id"
        :key="product.priceId"
        class="flex flex-col items-center justify-center custom-plan-card border-2 py-2 px-8"
        :class="product.checkedPlan ? 'checked' : ''"
      >
        <span class="is-size-6 my-1">
          {{ product.title }}
        </span>
        <span :if="product.savePrice !== ''" class="is-size-7 subtitle-blue">
          {{ product.savePrice }}
        </span>
        <span class="is-size-4 mt-2">
          <strong>
            {{ product.priceWithCurrency }}
          </strong>
        </span>
        <ul class="list-features-plan my-2">
          <li class="flex">
            <img class="img-icon" src="@/assets/icons/checkbold_icon.svg" />
            <span class="ml-1">
              {{ $t("pro_website_features.custom_domain") }}
            </span>
          </li>
          <li class="flex">
            <img class="img-icon" src="@/assets/icons/checkbold_icon.svg" />
            <span class="ml-1">
              {{ $t("pro_website_features.ssl_certificate") }}
            </span>
          </li>
          <li class="flex">
            <img class="img-icon" src="@/assets/icons/checkbold_icon.svg" />
            <span class="ml-1">
              {{ $t("pro_website_features.web_hosting") }}
            </span>
          </li>
          <li class="flex">
            <img class="img-icon" src="@/assets/icons/checkbold_icon.svg" />
            <span class="ml-1">
              {{ $t("pro_website_features.embeddable_widget") }}
            </span>
          </li>
          <li class="flex">
            <img class="img-icon" src="@/assets/icons/checkbold_icon.svg" />
            <span class="ml-1">
              {{ $t("pro_website_features.payment_via_whatsapp") }}
            </span>
          </li>
          <li class="flex">
            <img class="img-icon" src="@/assets/icons/checkbold_icon.svg" />
            <span class="ml-1">
              {{ $t("pro_website_features.remove_biew_watermark") }}
            </span>
          </li>
          <li class="flex">
            <img class="img-icon" src="@/assets/icons/checkbold_icon.svg" />
            <span class="ml-1">
              {{ $t("pro_website_features.integrations") }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-button flex flex-col items-center justify-center my-4">
      <b-button
        type="is-dark"
        class="text-center font-medium rounded mt-4 px-4 w-60"
        :disabled="disableContinueButton"
        @click.prevent="handleClickContinue"
      >
        <span>{{ $t("continue") }}</span>
      </b-button>
      <b-button
        type="is-ghost"
        class="text-center font-medium rounded mt-4 link-button w-60"
        @click="handleClickReturn"
      >
        {{ $t("subscription_modal.return") }}
      </b-button>
    </div>
    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
      :can-cancel="false"
    >
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin">
      </b-icon>
    </b-loading>
  </div>
</template>
<style lang="scss" scoped>
span.subtitle-blue {
  color: #0049b8;
}
.plan-cards {
  position: relative;
}
#id-plan-card-monthly.checked {
  opacity: 1;
  transform: translateX(150px);
}
#id-plan-card-monthly {
  transition: all 0.2s ease-in-out;
  transform: translate(-100px, -10px);
  cursor: default;
  cursor: auto;
  opacity: 0;
}
#id-plan-card-yearly {
  transition: all 0.2s ease-in-out;
  transform: translate(100px, -10px);
  cursor: default;
  opacity: 0;
}
#id-plan-card-yearly.checked {
  opacity: 1;
  cursor: auto;
  transform: translateX(-150px);
}
.custom-plan-card {
  width: 380px;
  border-radius: 0.75rem;
  box-shadow: 2px 4px 14px 0px rgba(0, 0, 0, 0.25);
}
ul.list-features-plan > li {
  margin: 4px 0;
}
.link-button {
  border: 0;
  padding: 0;
  color: #0a69ff;
  &:hover {
    color: #2b7cfe;
    background-color: #e2edff;
  }
}
*,
*::after,
* ::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background-color: rgba(230, 238, 249, 1);
}
.custom-tabs {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 2px solid #18a4df;
  padding: 0.2rem;
  border-radius: 100px;
}
.custom-tabs > * {
  z-index: 2;
}
.custom-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 180px;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 100px;
  padding: 0.75rem;
  margin: 0 rem;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}
input[type="radio"] {
  display: none;
}
input[type="radio"]:checked + label {
  color: #fff;
}
input[type="radio"]:checked + label > .notification {
  color: #fff;
}
input[id="id-rdo-1"]:checked ~ .glider {
  transform: translateX(0);
}
input[id="id-rdo-2"]:checked ~ .glider {
  transform: translateX(100%);
}
.glider {
  position: absolute;
  height: 40px;
  width: 180px;
  background-color: #141414;
  z-index: 1;
  border-radius: 100px;
  transition: 0.25s ease-in-out;
}
</style>
<script>
import { noop } from "lodash-es";
export default {
  props: {
    website: {
      type: Object,
      default: () => ({})
    },
    onClickReturn: {
      type: Function,
      default: noop
    },
    onClickContinue: {
      type: Function,
      default: noop
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      checked: this.website?.plan || ""
    };
  },
  computed: {
    formatedProducts() {
      const translations = {
        title_pro_website_per_month: this.$t(
          "pro_website_features.website_per_month"
        ),
        title_pro_website_per_year: this.$t(
          "pro_website_features.website_per_year"
        ),
        save_price: this.$t("pro_website_features.save")
      };
      return this.$store.getters.showCardProducts(this.checked, translations);
    },
    disableContinueButton() {
      return this.checked === this.website?.plan;
    },
    checkedMonthly() {
      return this.checked === "Month";
    },
    checkedYearly() {
      return this.checked === "Year";
    }
  },
  methods: {
    handleClickContinue() {
      const selectedProduct = this.formatedProducts.find(product =>
        Boolean(product.checkedPlan)
      );
      if (
        this.website &&
        selectedProduct &&
        selectedProduct.priceId !== this.website.priceId
      ) {
        this.onClickContinue(selectedProduct, this.website);
      }
    },
    handleClickReturn() {
      this.onClickReturn(["editOption"]);
    },
    handleChangeChecked(event) {
      const {
        target: { value }
      } = event;
      this.checked = value;
    }
  }
};
</script>
