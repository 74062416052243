import axios from "axios";

const model = "googleapis";

export default {
  oauth2(params) {
    return axios.get(`/api/${model}/oauth2`, params);
  },

  saveTokens(data) {
    return axios.post(`/api/${model}/saveTokens`, data);
  }
};
