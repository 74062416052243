<template>
  <div>
    <b-modal
      :active="activate"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      scroll="keep"
      class="flex flex-wrap"
      @close="$emit('close')"
    >
      <div id="id-div-suscription" class="modal-card modal-container">
        <section class="modal-card-body flex flex-grow flex-col rounded">
          <div id="div-suscription-header" class="flex mb-3 relative">
            <div class="flex justify-center flex-grow font-bold">
              <h1 class="title is-4">{{ modalTitle }}</h1>
            </div>
            <div class="close-icon">
              <img
                class="flex grow-0 close-btn items-center imgSmall"
                src="@/assets/icons/rounded-cross.svg"
                @click="$emit('close')"
              />
            </div>
          </div>
          <template v-if="showEditOptions">
            <edit-license
              :website="selectedWebsite"
              :on-click-return="removeKeysFromQuery"
              :on-click-change-subscription-period="
                handleClickChangeSubscriptionPeriod
              "
              :on-click-change-website-license="
                handleClickSwitchWebsiteSubscription
              "
              :on-click-change-payment-type="handleCreateCheckoutSession"
              :is-loading="isLoading"
            ></edit-license>
          </template>
          <template v-else-if="showEditPeriodForm">
            <change-subscription-period
              :on-click-return="removeKeysFromQuery"
              :website="selectedWebsite"
              :on-click-continue="handleClickUpgradeSubscription"
              :is-loading="isLoading"
            ></change-subscription-period>
          </template>
          <template v-else-if="showSwitchSubscriptionForm">
            <switch-subscription-to-website
              :on-click-return="removeKeysFromQuery"
              :on-click-save="handleSwitchSubscriptions"
              :pro-website="selectedWebsite"
            >
            </switch-subscription-to-website>
          </template>
          <template v-else>
            <list-subscriptions
              :websites="proWebsites"
              :handle-click-edit="onClickEditionMode"
              :handle-click-cancel-subscription="onClickCancelSubscription"
              :handle-click-reactivate-subscription="
                handleClickReactivateSubscription
              "
            ></list-subscriptions>
          </template>
        </section>
        <confirmation-modal
          :show="showCancelSubscription"
          accept-text="Unsubscribe"
          @cancel="uncancelSubscription"
          @accept="cancelSubscription"
        >
          <p>
            {{ $t("site_options_menu.doYouCancel") }}
            <span class="font-bold"> {{ websiteDomain }}</span
            >?
          </p>
          <p>{{ $t("site_options_menu.doYouCancel2") }}</p>
        </confirmation-modal>
        <confirmation-modal
          :show="showReactivateSubscriptionModal"
          accept-text="Resubscribe"
          accept-text-style-type="dark"
          @cancel="uncancelSubscription"
          @accept="reactivateSubscription"
        >
          <p>
            {{ askReactivateSubscription }}
          </p>
        </confirmation-modal>
      </div>
    </b-modal>
    <b-loading
      :is-full-page="true"
      :active.sync="isFullpageLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>
<style lang="scss" scoped>
.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
#id-div-suscription {
  min-height: 512px;
}
.divider {
  width: 100%;
  border-bottom: 1px solid #bebec2;
}
.modal-content-container {
  max-width: 640px;
  width: 100%;
  .modal-center-content {
    max-width: 500px;
    width: 100%;
  }
}
.img-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.imgSmall {
  width: 32px;
  height: 32px;
  max-width: none !important;
  cursor: pointer;
}
.modal-container {
  max-width: 900px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.link-button {
  border: 0;
  padding: 0;
  width: auto;
  color: #0a69ff;
  &:hover {
    color: #2b7cfe;
  }
}
.border-red {
  border: 1px solid red;
}
.border-blue {
  border: 1px solid blue;
}
.border-green {
  border: 1px solid green;
}
@media (max-width: 640px) {
  .link-button {
    width: 100%;
  }
}
</style>
<script>
import { mapState } from "vuex";
import * as Sentry from "@sentry/browser";
import ApiPayments from "@/services/payments";
import ApiSites from "@/services/sites";
import utils from "@/utils/functions";
import ListSubscriptions from "./ListSubscriptions";
import ChangeSubscriptionPeriod from "./ChangeSubscriptionPeriod";
import SwitchSubscriptionToWebsite from "./SwitchSubscriptionToWebsite.vue";
import EditLicense from "./EditLicense";
import { isPlainObject, size, isFunction } from "lodash-es";
import SuccessIcon from "@/assets/icons/sucess-icon.svg";
import ConfirmationModal from "@/components/ConfirmationModal";

export default {
  components: {
    ListSubscriptions,
    EditLicense,
    ChangeSubscriptionPeriod,
    SwitchSubscriptionToWebsite,
    ConfirmationModal
  },
  props: {
    activate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedWebsite: null,
      editionMode: false,
      isLoading: false,
      isCancelingSubscription: false,
      isReactivatingSubscription: false
    };
  },
  computed: {
    ...mapState({
      websites: "websites",
      customerId: state => state.user.stripeCustomerId
    }),
    proWebsites: {
      get() {
        return this.$store.getters.proWebsites.map(utils.formatWebsite);
      }
    },
    askReactivateSubscription() {
      return String(
        this.$t("subscription_modal.ask_reactivate_subscription")
      ).replace(/{{name}}/, this.websiteDomain);
    },
    modalTitle: function title() {
      let title = "";
      const selectedMenuOption = this.$route?.query?.selectedMenuOption || "";
      const subscriptionId = this.$route?.query?.subscriptionId || "";
      const editOption = this.$route?.query?.editOption || "";

      const notSelectedWesite = [
        this.selectedWebsite === null,
        selectedMenuOption.trim() === "subscription",
        subscriptionId.trim() === "",
        editOption.trim() === ""
      ].every(Boolean);

      const isSelectedAWebsite = [
        this.selectedWebsite !== null,
        selectedMenuOption.trim() === "subscription",
        subscriptionId.trim() !== "",
        editOption.trim() === ""
      ].every(Boolean);

      const isChangingPlanType = [
        this.selectedWebsite !== null,
        selectedMenuOption.trim() === "subscription",
        subscriptionId.trim() !== "",
        editOption.trim() === "change_period"
      ].every(Boolean);

      const isSwitchingWebsiteLicense = [
        this.selectedWebsite !== null,
        selectedMenuOption.trim() === "subscription",
        subscriptionId.trim() !== "",
        editOption.trim() === "switch_subscription"
      ].every(Boolean);

      switch (true) {
        case notSelectedWesite: {
          title = this.$t("subscription_modal.title");
          break;
        }
        case isSelectedAWebsite: {
          title = this.$t("subscription_modal.edit_pro_subscriptions");
          break;
        }
        case isChangingPlanType: {
          title = this.$t("subscription_modal.change_subscription");
          break;
        }
        case isSwitchingWebsiteLicense: {
          title = this.$t("subscription_modal.assign_subscription_title");
          break;
        }
        default:
          title = this.$t("subscription_modal.title");
      }
      return title;
    },
    currentComponent() {
      return ListSubscriptions;
    },
    isFullpageLoading() {
      return this.isCancelingSubscription || this.isReactivatingSubscription;
    },
    showEditOptions() {
      return (
        this.selectedWebsite !== null &&
        this.$route.query?.subscriptionId !== undefined &&
        this.$route.query?.editOption === undefined
      );
    },
    websiteDomain() {
      return size(this.selectedWebsite) > 0
        ? this.selectedWebsite.subdomain
        : "";
    },
    showCancelSubscription() {
      return (
        this.selectedWebsite !== null &&
        this.$route.query?.subscriptionId !== undefined &&
        this.$route.query?.editOption === "cancel_subscription"
      );
    },
    showReactivateSubscriptionModal() {
      return (
        this.selectedWebsite !== null &&
        this.$route.query?.subscriptionId !== undefined &&
        this.$route.query?.editOption === "reactivate_subscription"
      );
    },
    showEditPeriodForm() {
      return (
        this.selectedWebsite !== null &&
        this.$route.query?.editOption === "change_period"
      );
    },
    showSwitchSubscriptionForm() {
      return (
        this.selectedWebsite !== null &&
        this.$route.query?.editOption === "switch_subscription"
      );
    }
  },
  watch: {
    proWebsites: function() {
      this.setSelectedWebsite(this.$route);
    },
    $route: function(toRoute) {
      this.setSelectedWebsite(toRoute);
    }
  },
  methods: {
    setSelectedWebsite(route = {}) {
      const { query = {} } = route;
      const { selectedMenuOption = "", subscriptionId = "" } = query;
      if (
        selectedMenuOption.trim() === "subscription" &&
        subscriptionId.trim() !== "" &&
        this.proWebsites.length > 0
      ) {
        const selectedWebsite = this.proWebsites.find(
          website => website.subscriptionId === subscriptionId
        );
        this.selectedWebsite = selectedWebsite || null;
      } else {
        this.selectedWebsite = null;
      }
    },
    async handleCreateCheckoutSession(subscriptionId) {
      const customerId = this.customerId;
      if (subscriptionId.trim() !== "") {
        try {
          const urlRedirect = this.$route.fullPath;
          const requestBody = {
            customerId,
            subscriptionId,
            urlRedirect
          };
          this.isLoading = true;
          const response = await ApiPayments.createCheckoutSession(requestBody);
          this.isLoading = false;
          const {
            data: { url }
          } = response;
          window.location.href = url;
        } catch (error) {
          const notification = {
            message: this.$t("error_messages.create_payment_session"),
            type: "is-danger"
          };
          this.$buefy.notification.open(notification);
        }
      }
    },
    onClickEditionMode(event, websiteId) {
      if (websiteId !== "") {
        const selectedWebsite = this.proWebsites.find(
          ({ id }) => websiteId === id
        );
        if (utils.objectSize(selectedWebsite) > 0) {
          const { query = {} } = this.$route;
          const { subscriptionId = "" } = selectedWebsite;
          const newQuery = {
            ...query,
            subscriptionId
          };
          this.$router.replace({ query: newQuery });
        }
      }
    },
    async onClickCancelSubscription(event, websiteId = "") {
      const selectedWebsite = this.proWebsites.find(
        ({ id }) => websiteId === id
      );
      if (utils.objectSize(selectedWebsite) > 0) {
        const { query = {} } = this.$route;
        const { subscriptionId = "" } = selectedWebsite;
        const newQuery = {
          ...query,
          subscriptionId,
          editOption: "cancel_subscription"
        };
        this.$router.replace({ query: newQuery });
      }
    },
    async cancelSubscription() {
      this.isCancelingSubscription = true;
      try {
        this.isCancelingSubscription = true;
        const {
          id = "",
          uid = "",
          subscriptionId = "",
          subdomain = "",
          netlify = "",
          limitDate = ""
        } = this.selectedWebsite;
        await ApiPayments.cancelSubscription({
          websiteId: id,
          subdomain: subdomain,
          netlifyId: netlify,
          subscriptionId: subscriptionId,
          uid: uid
        });
        const formatedMessage = String(
          this.$t("subscription_modal.canceled_successfully") || ""
        ).replace(/{{date}}/, limitDate);
        this.$buefy.notification.open({
          message: formatedMessage,
          type: "is-link",
          duration: 10000
        });
        const websiteRes = await ApiSites.findMyWebsites();
        this.$store.dispatch("setWebsites", websiteRes.data || []);
      } catch (error) {
        this.$buefy.notification.open({
          message:
            error?.message || this.$t("error_messages.cancel_subscription"),
          type: "is-danger"
        });
      } finally {
        this.isCancelingSubscription = false;
        this.removeKeysFromQuery(["editOption", "subscriptionId"]);
      }
    },
    async reactivateSubscription() {
      this.isReactivatingSubscription = true;
      const { subscriptionId = "", subdomain = "" } = this.selectedWebsite;
      try {
        // eslint-disable-next-line no-unused-vars
        const { data } = await ApiPayments.reactivateSubscription(
          subscriptionId,
          {
            reactivationDate: Date.now()
          }
        );
        const formatedMessage = String(
          this.$t("success_messages.reactivated_subscription")
        ).replace(/{name}/, data?.domain || "");
        this.$buefy.notification.open({
          message: formatedMessage,
          type: "is-link",
          duration: 5000
        });
        const websiteRes = await ApiSites.findMyWebsites();
        this.$store.dispatch("setWebsites", websiteRes.data || []);
      } catch (error) {
        const formatedMessage = String(
          this.$t("error_messages.reactivated_subscription")
        ).replace(/{name}/, subdomain);
        this.$buefy.notification.open({
          message: error?.message || formatedMessage,
          type: "is-danger"
        });
      } finally {
        this.isReactivatingSubscription = false;
        this.removeKeysFromQuery(["editOption", "subscriptionId"]);
      }
    },
    uncancelSubscription() {
      this.removeKeysFromQuery(["editOption", "subscriptionId"]);
    },
    removeKeysFromQuery(keysToRemove = []) {
      this.selectedWebsite = null;
      const { query = {} } = this.$route;
      const newQuery = utils.omitKeys(query, keysToRemove);
      const history = { query: newQuery };
      this.$router.replace(history);
    },
    currentPropsComponent() {
      let props = {
        websites: this.proWebsites,
        handleClickEdit: this.onClickEditionMode
      };
      return props;
    },
    handleClickChangeSubscriptionPeriod() {
      this.selectedWebsite = null;
      const { query = {} } = this.$route;
      const newQuery = {
        ...query,
        editOption: "change_period"
      };
      const history = { query: newQuery };
      this.$router.replace(history);
    },
    handleClickSwitchWebsiteSubscription() {
      this.selectedWebsite = null;
      const { query = {} } = this.$route;
      const newQuery = {
        ...query,
        editOption: "switch_subscription"
      };
      const history = { query: newQuery };
      this.$router.replace(history);
    },
    async handleClickUpgradeSubscription(selectedProduct, website) {
      try {
        if (
          isPlainObject(selectedProduct) &&
          isPlainObject(website) &&
          size(selectedProduct) > 0 &&
          size(website) > 0
        ) {
          const requestObj = {
            customerId: this.customerId,
            subscriptionId: website.subscriptionId,
            priceId: selectedProduct.priceId,
            urlRedirect: this.$route.fullPath
          };
          this.isLoading = true;
          const response = await ApiPayments.upgradeSubscription(requestObj);
          const {
            data: { url }
          } = response;
          window.location.href = url;
        }
      } catch (error) {
        const notification = {
          message: this.$t("error_messages.update_subscription"),
          type: "is-danger"
        };
        this.$buefy.notification.open(notification);
      }
    },
    async handleClickReactivateSubscription(websiteId) {
      try {
        const selectedWebsite = this.proWebsites.find(
          ({ id }) => websiteId === id
        );
        if (utils.objectSize(selectedWebsite) > 0) {
          const { query = {} } = this.$route;
          const { subscriptionId = "" } = selectedWebsite;
          const newQuery = {
            ...query,
            subscriptionId,
            editOption: "reactivate_subscription"
          };
          this.$router.replace({ query: newQuery });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async handleSwitchSubscriptions(destWebsite, cb) {
      try {
        const { _id: destWebsiteId, name: destWebsiteName } = destWebsite;
        const { id: originWebsiteId } = this.selectedWebsite;
        await ApiSites.reasignSubscription(originWebsiteId, destWebsiteId);
        if (isFunction(cb)) cb();
        let msg =
          '<div style="display:flex;align-items:center;">' +
          '<img src="' +
          SuccessIcon +
          '" alt="img" style="display:inline-block;margin-right: 8px;" />';
        msg += String(this.$t("subscription_modal.sucessfully_update")).replace(
          /{name}/,
          destWebsiteName
        );
        msg += "</div>";
        this.$buefy.snackbar.open({
          message: msg,
          position: "is-top"
        });
        const res = await ApiSites.findMyWebsites({
          uid: this.userId
        });
        this.$store.dispatch("setWebsites", res.data || []);
      } catch (error) {
        if (isFunction(cb)) cb(error);
        this.$buefy.notification.open({
          message: this.$t("error_messages.switch_between_subscription"),
          type: "is-danger"
        });
      }
    }
  }
};
</script>
