<template>
  <div class="pb-16 mt-8">
    <div
      id="div-suscription-body-top"
      class="flex flex-grow flex-wrap justify-center modal-content-container"
    >
      <div class="modal-center-content flex flex-col">
        <span>
          <strong>
            {{ $t("subscription_modal.pro_sites") }}
          </strong>
        </span>
        <span class="my-4">
          {{ websites.length }}
        </span>
        <div class="divider"></div>
      </div>
    </div>
    <hr />
    <div
      v-if="websites.length > 0"
      id="div-suscription-body"
      class="flex flex-grow flex-wrap justify-center mt-4 modal-content-container"
    >
      <div
        v-for="website of websites"
        :key="website.id"
        class="flex flex-col modal-center-content mb-3"
      >
        <div class="flex justify-between items-center w-full mt-3">
          <span>
            <strong>{{ $t("subscription_modal.pro_website") }}</strong>
          </span>
          <span>{{ website.name }}</span>
        </div>
        <div class="flex justify-between items-center w-full mt-3">
          <span v-if="Boolean(website.cancelAtPeriodEnd)">
            <strong>
              {{ $t("subscription_modal.end_date") }}
            </strong>
          </span>
          <span v-else>
            <strong>
              {{ $t("subscription_modal.next_payment") }}
            </strong>
          </span>
          <span>{{ website.limitDate }}</span>
        </div>
        <div
          v-if="Boolean(website.cancelAtPeriodEnd)"
          class="flex w-full items-center justify-between mt-3 mb-6"
        >
          <b-button
            type="is-ghost"
            class="text-center font-medium rounded link-button"
            @click="handleClickReactivateSubscription(website.id)"
          >
            {{ $t("subscription_modal.resubscribe") }}
          </b-button>
        </div>
        <div v-else class="flex w-full items-center justify-between mt-3 mb-6">
          <span>
            <strong>
              {{ website.totalAmount }}
              {{ website.currency }}/{{ website.plan }}
            </strong>
          </span>
          <div
            :id="'id-div-action-' + website.id"
            class="flex w-24 justify-end"
          >
            <div @click.prevent="handleClickEdit($event, website.id)">
              <a>
                <img class="img-icon" src="@/assets/icons/edit.svg" />
              </a>
            </div>
            <div
              class="ml-2"
              @click.prevent="handleClickCancelSubscription($event, website.id)"
            >
              <a>
                <img class="img-icon" src="@/assets/icons/mi_delete.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-center-content divider"></div>
      <!-- REPORT LIST -->
    </div>
    <div
      v-else
      id="div-suscription-body"
      class="flex flex-grow flex-wrap justify-center mt-4 modal-content-container"
    >
      <div class="flex flex-col modal-center-content mb-3">
        <span>
          <strong>
            {{ $t("subscription_modal.next_payment") }}
          </strong>
        </span>
        <div class="flex justify-between items-start w-full my-3">
          {{ $t("subscription_modal.no_active_subscriptions") }}
        </div>
        <div class="divider"></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.divider {
  width: 100%;
  border-bottom: 1px solid #bebec2;
}
.modal-content-container {
  max-width: 640px;
  width: 100%;
  .modal-center-content {
    max-width: 500px;
    width: 100%;
  }
}
.img-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.border-red {
  border: 1px solid red;
}
.border-blue {
  border: 1px solid blue;
}
.border-green {
  border: 1px solid green;
}
.link-button {
  border: 0;
  padding: 0;
  color: #0a69ff;
  &:hover {
    color: #2b7cfe;
  }
}
</style>
<script>
import { noop } from "@/utils/functions";
export default {
  props: {
    websites: {
      type: Array,
      default: () => []
    },
    handleClickReactivateSubscription: {
      type: Function,
      default: noop
    },
    handleClickEdit: {
      type: Function,
      default: noop
    },
    handleClickCancelSubscription: {
      type: Function,
      default: noop
    }
  },
  computed: {
    proWebsitesLength() {
      return this.websites.length;
    }
  }
};
</script>
