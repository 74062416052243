export default {
  items: [],
  showShoppingCart: false,
  currency: {
    separator: ",",
    symbol: "",
    pattern: "!#",
    precision: 0,
    decimal: "."
  },
  selected: null,
  showProductOptionsModal: false,
  showCheckoutForm: false,
  form: {
    address: "",
    branch: "",
    city: "",
    country: "",
    deliveryZone: "",
    email: "",
    name: "",
    notes: "",
    paymentMethod: 0,
    phoneNumber: "",
    receiveMode: 0,
    state: "",
    zipCode: "",
    marker: null
  }
};
