export const NUM_LINK = 1;
export const NUM_CHECKOUT = 2;
export const NUM_DOWNLOAD = 3;

export const options = [
  { value: NUM_LINK, text: "Open link" },
  { value: NUM_CHECKOUT, text: "Add to shopping cart" }
];

export const CUSTOM_DOMAIN = "custom-domain";
export const BIEW_DOMAIN = "biew-domain";

export const validDomainTypes = [CUSTOM_DOMAIN, BIEW_DOMAIN];

export default {
  options,
  NUM_LINK,
  NUM_CHECKOUT,
  NUM_DOWNLOAD,
  CUSTOM_DOMAIN,
  BIEW_DOMAIN,
  validDomainTypes
};
