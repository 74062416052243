<template>
  <div class="main-layout has-background-white-ter overflow-y-auto">
    <div class="has-background-black">
      <div class="container">
        <b-navbar type="is-black">
          <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
              <img class="logo" src="@/assets/logo-white.png" alt="logo" />
            </b-navbar-item>
          </template>
          <template slot="start">
            <b-navbar-item class="has-text-weight-bold" href="#">
              {{ $t("builder.websites") }}
            </b-navbar-item>
          </template>
          <template slot="end">
            <b-navbar-item
              class="has-text-weight-bold"
              target="_blank"
              href="https://www.notion.so/9cb4119ddbc646949b794d6da494d5a8?v=7687ff496d9647598b5494a50219e60a"
            >
              {{ $t("builder.help") }}
              <b-icon name="question-circle" class="ml-3" />
            </b-navbar-item>
            <div class="relative flex items-center mr-4">
              <img
                class="h-8 w-8 rounded-full border-2 border-white cursor-pointer"
                :src="userProfileImage"
                :alt="$t('builder.profile_image')"
                @click="toggleMenu"
                @error="userImageError = true"
              />
              <div
                v-show="showMenu"
                ref="userMenu"
                class="absolute bg-white mt-60 shadow-md rounded-lg right-0 outline-none"
                tabindex="0"
                @focusout="closeMenu"
              >
                <div class="flex flex-col flex-grow profile_menu">
                  <menu-item
                    v-for="option of menuOptions"
                    :key="option.id"
                    v-bind="option"
                  ></menu-item>
                </div>
              </div>
            </div>
          </template>
        </b-navbar>
      </div>
    </div>
    <suscription-modal
      :activate="openSubscriptionModal"
      @close="openSubscriptionModal = false"
    />
    <router-view></router-view>
  </div>
</template>
<style scoped>
.img-icon {
  height: 24px;
}
.profile_menu {
  max-width: 223px;
}
.menu_item {
  width: 200px;
}
</style>
<script>
import ApiAuth from "@/services/auth";
import BIcon from "@/components/BIcon";
import firebaseAuth from "@/plugins/firebase/auth";
import SuscriptionModal from "@/components/SuscriptionModal/SuscriptionModal";
import MenuItem from "./components/MenuItem";
import CircleUserIcon from "@/assets/icons/circle-user-regular.svg";
import DolarSignIcon from "@/assets/icons/dollar-sign-solid.svg";
import QuestionIcon from "@/assets/icons/question-solid.svg";
import RightFromBracketIcon from "@/assets/icons/right-from-bracket-solid.svg";
import utils from "@/utils/functions";
const getMenuOptions = vm => [
  {
    id: "profile",
    srcIcon: CircleUserIcon,
    textOption: vm.$t("main_layout_topbar.menu_items.my_profile"),
    onClickHandler: vm.closeMenu
  },
  {
    id: "suscription",
    srcIcon: DolarSignIcon,
    textOption: vm.$t("main_layout_topbar.menu_items.subscription"),
    onClickHandler: vm.handleOpenSuscriptionModal
  },
  {
    id: "help",
    srcIcon: QuestionIcon,
    textOption: vm.$t("main_layout_topbar.menu_items.help"),
    onClickHandler: vm.closeMenu
  },
  {
    id: "logout",
    srcIcon: RightFromBracketIcon,
    textOption: vm.$t("main_layout_topbar.menu_items.log_out"),
    onClickHandler: vm.logOut,
    itemColorStyle: { color: "red" }
  }
];
export default {
  name: "MainLayout",
  components: {
    BIcon,
    SuscriptionModal,
    MenuItem
  },
  data() {
    const vm = this;
    return {
      showMenu: false,
      userImageError: false,
      openModal: false,
      menuOptions: getMenuOptions(vm)
    };
  },
  computed: {
    userProfileImage() {
      return this.userImageError
        ? require("@/assets/images/user.png")
        : this.$store.state.user.providerData[0].photoURL;
    },
    openSubscriptionModal: {
      get() {
        const { query } = this.$route;
        const selectedMenuOption = query?.selectedMenuOption;
        return selectedMenuOption === "subscription";
      },
      set(val) {
        const option = val ? "subscription" : "";
        this.setMenuOption(option);
      }
    }
  },
  methods: {
    setMenuOption: function setMenuOption(option = "") {
      const validOptions = ["", "subscription"];
      const { query } = this.$route;
      let history = {};
      if (validOptions.includes(option)) {
        if (option === "") {
          const tmpQuery = utils.omitKeys(query, [
            "selectedMenuOption",
            "subscriptionId",
            "editOption"
          ]);
          history = { name, query: tmpQuery };
        } else {
          const selectedMenuOption = option;
          history = {
            query: {
              ...query,
              selectedMenuOption
            }
          };
        }
        this.$router.replace(history);
      }
    },
    async logOut() {
      try {
        await firebaseAuth.signOut();
        ApiAuth.removeUID();
        this.$router.push({ name: "signin" }).catch(() => {});
      } catch (_) {
        console.log();
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      this.$nextTick(() => {
        this.$refs.userMenu.focus();
      });
    },
    closeMenu() {
      this.showMenu = false;
    },
    handleOpenSuscriptionModal() {
      this.openSubscriptionModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.main-layout {
  height: 100vh;
}
</style>
