import axios from "axios";

const model = "sheets";

export default {
  getSheet(sheetId, params) {
    return axios.get(`/api/${model}/${sheetId}`, params);
  },

  getSheetItems({ sheetId }) {
    return axios.get(`/api/${model}/${sheetId}/items`);
  }
};
