import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faAlignLeft } from "@fortawesome/free-solid-svg-icons/faAlignLeft";
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faColumns } from "@fortawesome/free-solid-svg-icons/faColumns";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { faGripLines } from "@fortawesome/free-solid-svg-icons/faGripLines";
import { faHeading } from "@fortawesome/free-solid-svg-icons/faHeading";
import { faImage } from "@fortawesome/free-solid-svg-icons/faImage";
import { faTable } from "@fortawesome/free-solid-svg-icons/faTable";

library.add(
  faAlignLeft,
  faBolt,
  faCheck,
  faColumns,
  faFilter,
  faGripLines,
  faHeading,
  faImage,
  faTable
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
