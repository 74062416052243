<template>
  <div class="flex p-1 items-center menu_item">
    <a
      class="button is-white p-1 w-full flex justify-start"
      :style="itemColorStyle"
      @click="onClickHandler"
    >
      <img class="img-icon pr-2" :src="srcIcon" />
      {{ textOption }}
    </a>
  </div>
</template>
<style scoped>
.img-icon {
  height: 20px;
}
.profile_menu {
  max-width: 223px;
}
.menu_item {
  width: 200px;
}
</style>
<script>
const { noop } = "@/utils/functions";
export default {
  props: {
    srcIcon: {
      type: String,
      default: ""
    },
    textOption: {
      type: String,
      default: ""
    },
    onClickHandler: {
      type: Function,
      default: noop
    },
    itemColorStyle: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
