import axios from "axios";

const model = "payments";

export default {
  createCustomer(data) {
    return axios.post(`/api/${model}/create-customer`, data);
  },

  cancelSubscription(data) {
    return axios.post(`/api/${model}/v2/cancel-subscription`, data);
  },
  reactivateSubscription(subscriptionId, body) {
    return axios.post(
      `/api/${model}/reactivate-subscription/${subscriptionId}`,
      body
    );
  },

  retryInvoice(data) {
    return axios.post(`/api/${model}/retry-invoice`, data);
  },

  createCheckoutSession(data) {
    return axios.post(`/api/${model}/create-checkout-session`, data);
  },

  upgradeSubscription(data) {
    return axios.post(`/api/${model}/upgrade-subscription`, data);
  }
};
