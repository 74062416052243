import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: "get-biew.firebaseapp.com",
  databaseURL: "https://get-biew.firebaseio.com",
  projectId: "get-biew",
  storageBucket: "get-biew.appspot.com",
  messagingSenderId: "280487002022",
  appId: "1:280487002022:web:8474cbf482c0b1c2ec9967",
  measurementId: "G-2ME57FRB9C"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
