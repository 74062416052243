<template>
  <div
    class="container containerDatePicker mx-auto px-4 sm:px-0"
    style="max-width: none; width: 100%;"
  >
    <div class="flex">
      <Button class="buttonMenuPicker" @click="showMenu()">
        <div>
          <img class="imgSmall" src="@/assets/icons/date-range.svg" />
          {{ texto }}
        </div>
        <img class="imgSmall" src="@/assets/icons/chevron-right.svg" />
      </Button>
      <transition name="fade">
        <div
          v-if="show"
          v-click-outside="closeOptionsMenu"
          class="menuDropDownButton"
        >
          <div>
            <button
              id="btnToday"
              class="btnToday"
              @click="showDate('btnToday')"
            >
              {{ $t("date_picker.today") }}
            </button>
          </div>
          <div>
            <button id="btnWeek" class="btnWeek" @click="showDate('btnWeek')">
              {{ $t("date_picker.this_week") }}
            </button>
          </div>
          <div>
            <button
              id="btnMonth"
              class="btnMonth"
              @click="showDate('btnMonth')"
            >
              {{ $t("date_picker.this_month") }}
            </button>
          </div>
          <div>
            <button id="btnYear" class="btnYear" @click="showDate('btnYear')">
              {{ $t("date_picker.this_year") }}
            </button>
          </div>
          <div class="letterdate_picker">
            <p>{{ $t("date_picker.from") }}</p>
            <p>{{ $t("date_picker.until") }}</p>
          </div>
          <div class="datePickerDiv">
            <date-picker
              v-model="timeStart"
              class="datePickerStyle"
              value-type="format"
              format="YYYY/MM/DD"
            ></date-picker>
            <date-picker
              v-model="timeEnd"
              class="datePickerStyle"
              value-type="format"
              format="DD/MM/YYYY"
            ></date-picker>
          </div>
          <div>
            <button id="btnApply" class="btnApply" @click="applyFilterDate()">
              {{ $t("date_picker.apply") }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// require("moment/locale/ar");
// require("moment/locale/de");
// require("moment/locale/es");
// require("moment/locale/fr");
// require("moment/locale/pt");

export default {
  name: "DatePickerComponent",

  components: {
    DatePicker
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  data() {
    return {
      texto: this.$t("date_picker.today"),
      textofinal: "",
      show: false,
      timeStart: "",
      timeEnd: ""
    };
  },
  computed: {},
  methods: {
    async showMenu() {
      this.show = !this.show;
    },
    async closeOptionsMenu() {
      this.show = false;
    },
    async showDate(id) {
      const selectElement1 = document.getElementById("btnToday");
      const selectElement2 = document.getElementById("btnWeek");
      const selectElement3 = document.getElementById("btnMonth");
      const selectElement4 = document.getElementById("btnYear");
      switch (id) {
        case "btnToday":
          selectElement1.style.backgroundColor = "#0a69ff";
          selectElement1.style.color = "#fff";

          selectElement2.style.backgroundColor = "#fff";
          selectElement2.style.color = "#262626";
          selectElement3.style.backgroundColor = "#fff";
          selectElement3.style.color = "#262626";
          selectElement4.style.backgroundColor = "#fff";
          selectElement4.style.color = "#262626";
          this.texto = this.$t("date_picker.today");
          break;
        case "btnWeek":
          selectElement2.style.backgroundColor = "#0a69ff";
          selectElement2.style.color = "#fff";

          selectElement1.style.backgroundColor = "#fff";
          selectElement1.style.color = "#262626";
          selectElement3.style.backgroundColor = "#fff";
          selectElement3.style.color = "#262626";
          selectElement4.style.backgroundColor = "#fff";
          selectElement4.style.color = "#262626";
          this.texto = this.$t("date_picker.this_week");
          break;
        case "btnMonth":
          selectElement3.style.backgroundColor = "#0a69ff";
          selectElement3.style.color = "#fff";

          selectElement1.style.backgroundColor = "#fff";
          selectElement1.style.color = "#262626";
          selectElement2.style.backgroundColor = "#fff";
          selectElement2.style.color = "#262626";
          selectElement4.style.backgroundColor = "#fff";
          selectElement4.style.color = "#262626";
          this.texto = this.$t("date_picker.this_month");
          break;
        case "btnYear":
          selectElement4.style.backgroundColor = "#0a69ff";
          selectElement4.style.color = "#fff";

          selectElement1.style.backgroundColor = "#fff";
          selectElement1.style.color = "#262626";
          selectElement2.style.backgroundColor = "#fff";
          selectElement2.style.color = "#262626";
          selectElement3.style.backgroundColor = "#fff";
          selectElement3.style.color = "#262626";
          this.texto = this.$t("date_picker.this_year");
          break;
        default:
          selectElement1.style.backgroundColor = "#fff";
          selectElement2.style.backgroundColor = "#fff";
          selectElement3.style.backgroundColor = "#fff";
          selectElement4.style.backgroundColor = "#fff";
          break;
      }
    },
    applyFilterDate() {
      if (this.timeStart != "" && this.timeEnd != "") {
        this.timeStart = this.convertStringDate(this.timeStart);
        this.timeEnd = this.convertStringDate(this.timeEnd);
        this.textofinal = this.timeStart + " - " + this.timeEnd;
      }
      this.show = false;
    },
    convertStringDate(date) {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.imgSmall {
  width: 20px;
  height: 20px;
  display: inline;
}
.buttonMenuPicker {
  width: 100%;
  height: 34px;
  flex-direction: row;
  background-color: transparent;
  border-radius: 5px;
  border-color: #d0d0d0;
  border-width: 0.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btnToday {
  background-color: #0a69ff;
  width: 160px;
  color: white;
  border-radius: 5px;
  margin: 2%;
  border-color: #d0d0d0;
  border-width: 0.5px;
}
.btnWeek {
  background-color: #fff;
  width: 160px;
  color: 262626;
  border-radius: 5px;
  margin: 2%;
  border-color: #d0d0d0;
  border-width: 0.5px;
}
.btnMonth {
  background-color: #fff;
  width: 160px;
  color: 262626;
  border-radius: 5px;
  margin: 2%;
  border-color: #d0d0d0;
  border-width: 0.5px;
}
.btnYear {
  background-color: #fff;
  width: 160px;
  color: 262626;
  border-radius: 5px;
  margin: 2%;
  border-color: #d0d0d0;
  border-width: 0.5px;
}
.btnApply {
  background-color: #121315;
  color: #fff;
  width: 142px;
  border-radius: 5px;
  margin: 2%;
  height: 40px;
}
.menuDropDownButton {
  display: flex;
  flex-direction: column;
  margin: 2%;
  width: 325px;
  top: 55px;
  left: -20px;
  z-index: 11;
  position: absolute;
  background-color: white;
}
.letterDatePicker {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top-width: 1px;
}
.datePickerDiv {
  display: flex;
  justify-content: center;
}
.datePickerStyle {
  width: 40%;
  margin: 2%;
}
</style>
