export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEMS = "DELETE_ITEMS";
export const INCREMENT_ITEM = "INCREMENT_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_SELECTED = "SET_SELECTED";
export const SHOW_CHECKOUT_FORM = "SHOW_CHECKOUT_FORM";
export const SHOW_SHOPPING_CART = "SHOW_SHOPPING_CART";
export const SHOW_PRODUCT_OPTIONS_MODAL = "SHOW_PRODUCT_OPTIONS_MODAL";
export const SUBSTRACT_ITEM = "SUBSTRACT_ITEM";

// Form fields
export const SET_FORM_ADDRESS = "SET_FORM_ADDRESS";
export const SET_FORM_BRANCH = "SET_FORM_BRANCH";
export const SET_FORM_CITY = "SET_FORM_CITY";
export const SET_FORM_COUNTRY = "SET_FORM_COUNTRY";
export const SET_FORM_EMAIL = "SET_FORM_EMAIL";
export const SET_FORM_DELIVERY_ZONE = "SET_FORM_DELIVERY_ZONE";
export const SET_FORM_PHONE = "SET_FORM_PHONE";
export const SET_FORM_NAME = "SET_FORM_NAME";
export const SET_FORM_NOTES = "SET_FORM_NOTES";
export const SET_FORM_PAYMENT_METHOD = "SET_FORM_PAYMENT_METHOD";
export const SET_FORM_RECEIVE_MODE = "SET_FORM_RECEIVE_MODE";
export const SET_FORM_STATE = "SET_FORM_STATE";
export const SET_FORM_ZIPCODE = "SET_FORM_ZIPCODE";
export const SET_FORM_MARKER = "SET_FORM_MARKER";
