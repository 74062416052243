import Vue from "vue";
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);

Vue.use(VueLazyload, {
  adapter: {
    loaded({ el }) {
      el.parentElement.classList.remove("animate-pulse");
    },
    loading(listener) {
      listener.el.parentElement.classList.add("animate-pulse");
    }
  }
});
