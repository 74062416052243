import * as mutation from "./mutation-types";

export default {
  [mutation.ADD_ITEM](state, item) {
    state.items = [...state.items, item];
  },

  [mutation.DELETE_ITEMS](state) {
    state.items = [];
  },

  [mutation.INCREMENT_ITEM](state, item) {
    state.items = state.items.map(cartItem => {
      if (cartItem.id === item.id) {
        cartItem.amount += 1;
      }
      return cartItem;
    });
  },

  [mutation.REMOVE_ITEM](state, item) {
    state.items = state.items.filter(cartItem => {
      return `${cartItem.id}-${cartItem.index}` !== `${item.id}-${item.index}`;
    });
  },

  [mutation.SET_CURRENCY](state, currency) {
    state.currency = currency;
  },

  [mutation.SET_SELECTED](state, selected) {
    state.selected = { ...selected };
  },

  [mutation.SHOW_CHECKOUT_FORM](state, show) {
    state.showCheckoutForm = show;
  },

  [mutation.SHOW_SHOPPING_CART](state, show) {
    state.showShoppingCart = show;
  },

  [mutation.SHOW_PRODUCT_OPTIONS_MODAL](state, show) {
    state.showProductOptionsModal = show;
  },

  [mutation.SUBSTRACT_ITEM](state, item) {
    state.items = state.items.map(cartItem => {
      if (cartItem.id === item.id) {
        cartItem.amount -= 1;
      }
      return cartItem;
    });
  },

  // Form fields
  [mutation.SET_FORM_ADDRESS](state, address) {
    state.form.address = address;
  },

  [mutation.SET_FORM_BRANCH](state, val) {
    state.form.branch = val;
  },

  [mutation.SET_FORM_CITY](state, val) {
    state.form.city = val;
  },

  [mutation.SET_FORM_COUNTRY](state, val) {
    state.form.country = val;
  },

  [mutation.SET_FORM_EMAIL](state, val) {
    state.form.email = val;
  },

  [mutation.SET_FORM_STATE](state, val) {
    state.form.state = val;
  },

  [mutation.SET_FORM_ZIPCODE](state, val) {
    state.form.zipCode = val;
  },

  [mutation.SET_FORM_DELIVERY_ZONE](state, deliveryZone) {
    state.form.deliveryZone = deliveryZone;
  },

  [mutation.SET_FORM_NAME](state, name) {
    state.form.name = name;
  },

  [mutation.SET_FORM_MARKER](state, marker) {
    state.form.marker = marker;
  },

  [mutation.SET_FORM_NOTES](state, val) {
    state.form.notes = val;
  },

  [mutation.SET_FORM_PAYMENT_METHOD](state, paymentMethod) {
    state.form.paymentMethod = paymentMethod;
  },

  [mutation.SET_FORM_PHONE](state, val) {
    state.form.phoneNumber = val;
  },

  [mutation.SET_FORM_RECEIVE_MODE](state, receiveMode) {
    state.form.receiveMode = receiveMode;
  }
};
