import Vue from "vue";
import VueRouter from "vue-router";
import firebaseAuth from "@/plugins/firebase/auth";
import store from "@/store";
import ApiAuth from "@/services/auth";
import * as Sentry from "@sentry/browser";

/* Layouts */
import LoginLayout from "@/layouts/LoginLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import SalesWebsite from "@/views/Dashboard/SalesWebsite.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: { name: "signin" }
  },
  {
    path: "/auth",
    name: "auth",
    component: LoginLayout,
    redirect: { name: "signin " },
    children: [
      {
        path: "/signin",
        name: "signin",
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/Login/Login.vue")
      },
      {
        path: "/oauth2callback",
        name: "oAuthHandler",
        component: () =>
          import(
            /* webpackChunkName: "o-auth-handler" */ "@/views/Login/OAuthHandler.vue"
          )
      }
    ]
  },
  {
    path: "/",
    name: "home",
    component: MainLayout,
    redirect: { name: "dashboard" },
    meta: { requiresAuth: true },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue"
          )
      }
    ]
  },
  {
    path: "/sales/:websiteUrl", // Define que la ruta acepta el parámetro "websiteUrl"
    name: "sales",
    component: SalesWebsite,
    props: true, // Indica que los parámetros se pasarán como props al componente
    redirect: { name: "salesSite" },
    meta: { requiresAuth: false },
    children: [
      {
        path: "/salesSite",
        name: "salesSite",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/Dashboard/SalesWebsite.vue"
          )
      }
    ]
  },
  {
    path: "/builder",
    name: "builder",
    component: () =>
      import(/* webpackChunkName: "builder" */ "@/views/Builder/Builder.vue"),
    meta: { requiresAuth: true },
    redirect: { name: "preview-list-view" },
    children: [
      {
        path: "home",
        name: "preview-list-view",
        component: () =>
          import(
            /* webpackChunkName: "preview-list-view" */ "@/views/BuilderPreview/ListView.vue"
          )
      },
      {
        path: "home/:slug",
        name: "preview-detail-view",
        component: () =>
          import(
            /* webpackChunkName: "preview-detail-view" */ "@/views/BuilderPreview/DetailView.vue"
          )
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, _, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

firebaseAuth.onAuthStateChanged(user => {
  if (user) {
    ApiAuth.setUID(user.uid);
    Sentry.setUser({
      email: user.email,
      username: user.displayName,
      id: user.uid
    });
    window.drift.identify(user.uid, {
      email: user.email,
      name: user.name
    });
    window.drift.load("hrtfz4fw3fuk");
    store.commit("SET_USER", user);
    const { 1: params } = router.history._startLocation.split("?");
    const query = {};
    if (params && params.length > 0) {
      let tmpQuery = new URLSearchParams(params);
      if (tmpQuery.size > 0) {
        for (const [key, value] of tmpQuery.entries()) {
          query[key] = value;
        }
      }
    }
    router.push({ name: "dashboard", query });
  }
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const currentUser = firebaseAuth.currentUser;
  if (requiresAuth && !currentUser) {
    next("/signin");
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
