const googleDriveDirectDownloadLink = link => {
  const idExtractor = /\/d\/(.+?)(?:\/|#|\?|$)|id=(.*)/;
  const result = idExtractor.exec(link);

  if (!result) {
    return false;
  }

  const docId = result[1] || result[2];

  let docType = "file";
  let finalLink = "";

  if (link.startsWith("https://docs.google.com/presentation")) {
    docType = "presentation";
    finalLink =
      "https://docs.google.com/presentation/d/" + docId + "/export/pptx";
  } else if (link.startsWith("https://docs.google.com/document")) {
    docType = "document";
    finalLink =
      "https://docs.google.com/document/d/" + docId + "/export?format=docx";
  } else if (link.startsWith("https://docs.google.com/spreadsheets")) {
    docType = "document";
    finalLink =
      "https://docs.google.com/spreadsheets/d/" + docId + "/export?format=xlsx";
  } else if (docType === "file") {
    finalLink = "https://drive.google.com/thumbnail?id=" + docId;
  } else {
    return false;
  }

  return finalLink;
};

const imgurDirectDownloadLink = link => {
  const idExtractor = /imgur.com\/(.*)/;
  const result = idExtractor.exec(link);
  const imgId = result[1];

  if (!imgId) {
    false;
  }

  return "https://imgur.com/" + imgId;
};

const truncate = (value, size) => {
  if (!value) return "";
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + "...";
};

export const capitalize = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export default {
  directDownloadLink(link) {
    if (link && link.includes("google.com")) {
      return googleDriveDirectDownloadLink(link);
    } else if (link.includes("imgur.com")) {
      return imgurDirectDownloadLink(link);
    }

    return link;
  },
  truncate,
  capitalize
};
