import firebase from "./index";

const storage = firebase.storage;

export const uploadTask = async ({ path, image }) => {
  return new Promise((resolve, reject) => {
    const storageRef = storage()
      .ref()
      .child(path);
    const uploadTask = storageRef.put(image);

    uploadTask.on(
      storage.TaskEvent.STATE_CHANGED,
      () => {},
      error => {
        reject(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          resolve(downloadURL);
        });
      }
    );
  });
};

export default storage;
